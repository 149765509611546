import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import useLandingImage from "../hooks/useLandingImage"
import LineButton from "./LineButton"
import PrimaryBtn from "./PrimaryButton"
import zheluTitle from "../images/img_zl-logo_text-only.svg"

function Hero() {
  const landingImage = useLandingImage().index

  return (
    <div
      id="HeroSection"
      className="font-serif relative min-h-screen w-full flex items-center bg-cover bg-center"
    >
      <div className="flex absolute top-0 left-0 w-full h-full">
        <GatsbyImage
          image={landingImage}
          className="h-full w-full"
          objectFit={"cover"}
          alt={"喆律法律事務所"}
        />
      </div>
      <div className="overlay bg-gray-700 opacity-75" />
      <div className="container z-10 relative justify-center text-center">
        <div className="w-full h-auto mx-auto flex justify-center">
          <img src={zheluTitle} alt="喆律法律事務所" loading={"lazy"} />
        </div>

        <h3 className="hero-description relative text-2xl text-white mx-auto mt-10 p-6 table sm:text-4xl">
          為當事人圓滿解決法律爭議，是我們一直以來恪守的原則。
        </h3>
        <div className="text-center mt-8 sm:mt-12">
          <LineButton>
            <PrimaryBtn>免費法律諮詢</PrimaryBtn>
          </LineButton>
        </div>
      </div>
    </div>
  )
}

export default Hero
