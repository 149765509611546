import React from "react"
import Hero from "../components/Hero"

const PageNotFund = () => (
  <div className="container  mx-auto">
    <div>Hello world! 404</div>
    <Hero />
  </div>
)
export default PageNotFund
