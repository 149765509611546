import React from "react"
import PropTypes from "prop-types"

const PrimaryButton = ({ children, size = "medium" }) => {
  const classNameCommon =
    "inline-block font-body text-white rounded bg-primary border border-primary hover:bg-forhover transition duration-200 ease-linear"
  const classNameAll =
    "px-4 py-2 sm:text-xl sm:py-3 sm:px-5 lg:text-2xl lg:px-6"
  const classNameNormal = "px-4 py-2 lg:text-xl lg:py-3 lg:px-5"
  const classNameMedium = "text-xl py-3 px-5 sm:text-2xl sm:px-6"

  let classOutput = ""

  switch (size) {
    case "all":
      classOutput = `${classNameCommon} ${classNameAll}`
      break
    case "normal":
      classOutput = `${classNameCommon} ${classNameNormal}`
      break
    case "medium":
      classOutput = `${classNameCommon} ${classNameMedium}`
      break
    default:
      break
  }

  return (
    <>
      <div className={classOutput}>{children}</div>
    </>
  )
}

export default PrimaryButton

PrimaryButton.propTypes = {
  children: PropTypes.any,
  size: PropTypes.string,
}
